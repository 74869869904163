import React from 'react'
import { Link } from 'gatsby'

function FixedLink({ content, condition }) {
  const { url, title } = content || {}
  // console.log(`FixedLink condition=${condition} url=${url} title=${title}`)
  return (
    <div>
      <div className="z-10 fixed bottom-0 left-0 md:bg-white w-full md:border-t-4 md:border-gp-slate-70">
        <div className="md:max-w-5xl mx-auto flex justify-center items-center px-6 py-2">
          <Link
            className="btn btn--primary btn--blockMobile"
            to={
              url ||
              (condition
                ? `/select-condition?condition=${condition}`
                : '/select-condition')
            }
          >
            {title || 'Start Free Assessment'}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FixedLink
