import React, { useState } from 'react'
import { graphql ,Link} from 'gatsby'
import Img from 'gatsby-image'
import { Link as ScrollLink, Element } from 'react-scroll'
import SEO from '../components/SEO'
import RichText from '../utils/richTextUtils'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import ArticleTags from '../components/Articles/ArticleTags'
import FixedLink from '../components/Articles/FixedLink'
import ArticleContributors from '../components/Articles/ArticleContributors'
import { getConditionFromLearnCenter } from 'goodpath-common'

function ArticlePage({ data: { article, learnCenter }, location }) {
  const learnCenterName = learnCenter?.name?.toLowerCase() || ''
  const defaultCondition = getConditionFromLearnCenter(learnCenterName)

  const [anchorLinks, setAnchorLinks] = useState({})

  return (
    <>
      <SEO
        title={article.title}
        metaTitle={article.metaTitle}
        description={article.description}
        location={location}
        image={
          article.heroImage ? `https:${article.heroImage.fluid.src}` : null
        }
      />

      <FixedLink content={article.button} condition={defaultCondition} />

      <div className="bg-brand-pale-grey">
        <div className="items-center justify-center max-w-5xl px-4 py-2 mx-auto leading-snug md:w-11/12 md:px-8 bg-brand-white">
          {learnCenter && (
            <div>
              <Link to={`/${learnCenter.slug}`} className="inline-flex mt-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="inline-block w-4 h-4 mt-1 mr-1 "
                  fill="#372c72"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                </svg>

                <span className="text-base">
                  {learnCenter.name || learnCenter.pageTitle}
                </span>
              </Link>
            </div>
          )}

          <div className="pt-2__gp px-2__gp">
            <ArticleTags tags={article.tags} />
          </div>
          <div className="mt-4 brand">
            <h1 className="mt-8">{article.title}</h1>
          </div>

          {article.heroImage && (
            <div>
              <Img alt={article.title} fluid={article.heroImage.fluid} />
            </div>
          )}
          <ArticleContributors
            authors={
              article.authors || (article.author ? [article.author] : null)
            }
            reviewers={article.reviewers}
            date={article.publishDate}
          />
          <div className="p-4 brand">
            <RichText
              richTextDocument={article.body.json}
              options={{
                [BLOCKS.EMBEDDED_ASSET]: {
                  options: {
                    className: 'flex items-start justify-center pt-4 pb-4'
                  }
                },
                [BLOCKS.OL_LIST]: {
                  options: {
                    className: 'article_numbers--primary'
                  }
                },
                [BLOCKS.UL_LIST]: {
                  options: {
                    className: 'article_bullets--primary'
                  }
                },
                [BLOCKS.EMBEDDED_ENTRY]: {
                  options: {
                    className: 'justify-center pt-4 pb-4'
                  }
                },
                [BLOCKS.HEADING_6]: {
                  renderer: (node, children = [], props = {}) => {
                    if (children.length === 0) return null
                    let name
                    children.forEach((content) => {
                      if (!content || content?.length === 0 || !content[0])
                        return
                      const textNode = content[0]

                      const match = textNode.match(/\(name=([^)]+)\)/)
                      const _name = match && match.length > 0 ? match[1] : null

                      if (_name) {
                        name = _name
                        content[0] = content[0].replace(`(name=${_name})`, '')
                      }
                    })

                    return (
                      <>
                        {name && !anchorLinks[name] && (
                          <ScrollLink
                            smooth={true}
                            spy={true}
                            to={name}
                            offset={-130}
                            hashSpy={true}
                            isDynamic
                          />
                        )}
                        <p>
                          {name ? (
                            <Element name={name}>{children}</Element>
                          ) : (
                            children
                          )}
                        </p>
                      </>
                    )
                  }
                },
                [BLOCKS.PARAGRAPH]: {
                  renderer: (node, children, props = {}) => {
                    return (
                      <p {...props} className={`${props.className || ''}`}>
                        <RichText
                          richTextDocument={node}
                          options={{
                            [INLINES.HYPERLINK]: {
                              renderer: (node, children, props = {}) => {
                                const {
                                  data: { uri }
                                } = node
                                const isAnchorLink = uri.startsWith('#')
                                const name = uri.substring(1)
                                if (isAnchorLink && !anchorLinks[name]) {
                                  setAnchorLinks({
                                    ...anchorLinks,
                                    [name]: true
                                  })
                                }
                                return isAnchorLink ? (
                                  <ScrollLink
                                    smooth={true}
                                    spy={true}
                                    to={name}
                                    offset={-130}
                                    hashSpy={true}
                                    isDynamic
                                    className="cursor-pointer"
                                  >
                                    {children}
                                  </ScrollLink>
                                ) : (
                                  <a
                                    {...props}
                                    className={`${props.className || ''}`}
                                    href={node.data.uri}
                                  >
                                    {children}
                                  </a>
                                )
                              }
                            }
                          }}
                        />
                      </p>
                    )
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ArticlePageQuery($articleId: String!, $learnCenterId: String!) {
    article: contentfulArticle(id: { eq: $articleId }, node_locale: { eq: "en-US" }) {
      title
      metaTitle
      description
      slug
      heroImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      body {
        json
      }
      author {
        id
        name
        title
        image {
          fixed(width: 80, height: 80, quality: 100) {
            ...GatsbyContentfulFixed
          }
          title
        }
        showOnTeamPage
      }
      authors {
        id
        name
        title
        image {
          fixed(width: 80, height: 80, quality: 100) {
            ...GatsbyContentfulFixed
          }
          title
        }
        showOnTeamPage
      }
      reviewers {
        id
        name
        title
        image {
          fixed(width: 80, height: 80, quality: 100) {
            ...GatsbyContentfulFixed
          }
          title
        }
        showOnTeamPage
      }
      publishDate(formatString: "MMMM Do, YYYY")
      tags
      button {
        ...LinkFragment
      }
    }
    learnCenter: contentfulLearnCenter(id: { eq: $learnCenterId }, node_locale: { eq: "en-US" }) {
      pageTitle
      name
      slug
    }
  }
`

export default ArticlePage
