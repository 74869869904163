import React, { Fragment } from 'react'
import { Link } from 'gatsby'

function ArticleContributors({ authors, reviewers, date }) {
  if (!authors && !reviewers) return null

  return (
    <div className="flex my-6 flex-wrap w-full px-4 flex-col lg:flex-row justify-between">
      <div className="flex items-center flex-row brand">
        <div className="p">
          {authors?.length > 0 && (
            <>
              Written by{' '}
              {authors.map((p, i) => (
                <Fragment key={p.id}>
                  {i > 0 ? ' - ' : ''}
                  <PersonLinkWrapper person={p}>
                    {p.name}
                    {p.title ? `, ${p.title}` : ''}
                  </PersonLinkWrapper>
                </Fragment>
              ))}{' '}
            </>
          )}
          {reviewers?.length > 0 && (
            <>
              {` ${
                authors?.length > 0 ? 'and medically' : 'Medically'
              } reviewed by `}
              {reviewers.map((p, i) => (
                <Fragment key={p.id}>
                  {i > 0 ? ' - ' : ''}
                  <PersonLinkWrapper person={p}>
                    {p.name}
                    {p.title ? `, ${p.title}` : ''}
                  </PersonLinkWrapper>
                </Fragment>
              ))}
            </>
          )}
          <div>{date}</div>
        </div>
      </div>
    </div>
  )
}

const PersonLinkWrapper = ({ person, children }) => {
  const Container = person.showOnTeamPage ? Link : Fragment
  return (
    <Container to={`/team#${person.name.replace(/ /g, '-')}`}>
      {children}
    </Container>
  )
}

export default ArticleContributors
